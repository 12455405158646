<template>
  <div class="info-frecuentes mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Informe de clientes frecuentes</v-card-title>
          <v-card-subtitle>
            Desde este apartado, puedes estar al tanto de la cantidad de veces
            que tus clientes visitan tu centro, en el periodo de fechas de tu
            elección.
          </v-card-subtitle>
          <v-card-subtitle>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  dense
                  :headers="headers"
                  :items="clientes"
                  :loading="load_table"
                  :footer-props="{ itemsPerPageText: 'Clientes' }"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row dense>
                        <v-col cols="4" class="px-0">
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                        <v-btn dark color="black" @click="download" :loading="loading"
                          >Descargar</v-btn
                        >
                      </v-col>
                        <!-- <v-col cols="4">
                          <v-btn color="black" dark @click="download()">
                            <v-icon>mdi-download</v-icon>
                            Descargar
                          </v-btn>
                        </v->col> -->
                      </v-row>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.detalle="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          @click="openDetalle(item)"
                          class="my-1"
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog" scrollable max-width="800px">
                  <v-card v-if="detalle">
                    <v-card-title>
                      {{ detalle.cliente }}
                    </v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" md="12" sm="12">
                          <v-card outlined elevation="0">
                            <v-card-title>Servicios</v-card-title>
                            <v-card-text>
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <!-- <th>Servicio</th> -->
                                      <th>Factura</th>
                                      <th>Cacntidad</th>
                                      <th>Fecha</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in detalle.servicios"
                                      :key="index"
                                    >
                                      <!-- <td>{{ item.servicio }}</td> -->
                                      <td>
                                        <router-link
                                          :to="
                                            '/detallefactura/' + item.id_factura
                                          "
                                        >
                                          <span>
                                            {{ item.folio | folio }}
                                          </span>
                                        </router-link>
                                      </td>
                                      <td>{{ item.cantidad }}</td>
                                      <td>
                                        {{ moment(item.fecha).format("ll") }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <v-card outlined elevation="0">
                            <v-card-title>Productos</v-card-title>
                            <v-card-text>
                              <v-simple-table dense>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <!-- <th>Producto</th> -->
                                      <th>Factura</th>
                                      <th>Cantidad</th>
                                      <th>Fecha</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in detalle.productos"
                                      :key="index"
                                    >
                                      <!-- <td>{{ item.producto }}</td> -->
                                      <td>
                                        <router-link
                                          :to="
                                            '/detallefactura/' + item.id_factura
                                          "
                                        >
                                          <span>
                                            {{ item.folio | folio }}
                                          </span>
                                        </router-link>
                                      </td>
                                      <td>{{ item.cantidad }}</td>
                                      <td>
                                        {{ moment(item.fecha).format("ll") }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import XLSX from "xlsx";
export default {
  data: () => ({
    load_table: false,
    loading: false,
    menu_1: false,
    menu_2: false,
    date: moment().format("YYYY-MM-DD"),
    date_fin: moment().format("YYYY-MM-DD"),
    search: "",
    headers: [
      {
        text: "ID / CC",
        value: "cedula",
        align: "start",
        sortable: false,
      },
      {
        text: "Cliente",
        value: "nombre",
        sortable: false,
      },
      {
        text: "Correo",
        value: "correo",
        sortable: false,
      },
      {
        text: "Telefono",
        value: "telefono",
        sortable: false,
      },
      {
        text: "Servicios realizados",
        value: "servicios",
        filterable: false,
      },
      {
        text: "Productos",
        value: "productos",
        filterable: false,
      },
      {
        text: "Detalle",
        value: "detalle",
        filterable: false,
        sortable: false,
      },
    ],
    clientes: [],
    dialog: false,
    detalle: null,
  }),
  methods: {
    loadFrecuentes() {
      this.load_table = true;
      const body = {
        route: "/clientes_frecuentes",
        params: {
          date: JSON.stringify({ inicio: this.date, fin: this.date_fin }),
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data.sort(
              (a, b) => parseFloat(a.servicios) < parseFloat(b.servicios) || -1
            );
          }
        })
        .finally(() => (this.load_table = false));
    },
    openDetalle(item) {
      this.detalle = {
        cliente: item.cliente,
        servicios: item.detalle.servicios,
        productos: item.detalle.productos,
      };
      this.dialog = true;
    },
    download() {
      this.loading = true;
      const data = this.clientes.map((cliente) => {
        return {
          "Cc/ID": cliente.cedula,
          Cliente: cliente.nombre,
          Correo: cliente.correo,
          telefono: cliente.telefono,
          "Servicios": cliente.servicios,
          Productos: cliente.productos
        };
      });

      const filename = "Clientes Frecuentes";
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
      this.loading = false
    },
    moment(input) {
      return moment(input);
    },
  },
  created() {
    this.loadFrecuentes();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  watch: {
    date(val) {
      this.loadFrecuentes();
      return this.date;
    },
    date_fin() {
      this.loadFrecuentes();
      return this.date_fin;
    },
  },
};
</script>
